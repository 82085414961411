.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.table-invoice-details .demo ul li + li {
  margin-top: 40px;
}
.loan-application-main-wrapper .invoice-title .title-heading {
  font-size: 25px !important;
}
.col-4 {
  padding: 0px !important;
}

.table > :not(caption) > * > * {
  line-height: 19px;
}

.table li {
  line-height: 18px;
}
.invoice-title {
  padding-bottom: 2px !important;
}

.invoice-left-side.first-invoce-tb ul {
  padding: 2px 22px !important;
}
.bank-detals-box {
  display: flex;
  margin-top: 2px;
}
.tableBox.row.m-0,
.tableBox.row.m-0 p,
.loan-application-main-wrapper .invoice-title p {
  font-size: 12px !important;
  line-height: 15px;
}
.table-invoice-details table tbody tr td {
  padding: 0px 5px !important;
  font-size: 12px;
}
.table-invoice-details table thead tr th {
  line-height: 0.9;
  font-size: 11px;
  padding: 3px 2px !important;
}

.table {
  margin-bottom: 0px;
  position: relative;
  background: white;
}

.loan-application-main-wrapper {
  padding: 0px 7px 0px 7px !important;
}
.table_box .tableBox .terms-text {
  padding: 4px 10px;
}

.table_box th {
  padding: 0px 0px !important;
}

.di-invoice-main-wrapper li {
  font-size: 12px !important;
  line-height: 18px !important;
}
@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media print {
  #button {
    visibility: hidden;
    
    position: fixed;
  }
}
